<template>
  <div class="mt-10 mb-5 px-2 sm:px-6 lg:px-2">
    <div class="px-4 sm:px-6 lg:px-8" style="margin: auto; max-width: 1200px">
      <!-- Header -->
      <div class="flex">
        <div class="pr-4 pt-1">
          <i class="fa-solid fa-arrow-left text-gray-500 text-xl cursor-pointer" @click="$router.push('/settings/messaging-channels')"></i>
        </div>
        <div class="flex-1">
          <div class="pt-0 text-2xl font-medium">
            <div class="">Add Inbox Channel</div>
            <div class="text-xs pt-1 font-normal text-gray-500">Setup channels from where customers can communicate with you.</div>
          </div>
        </div>
      </div>

      <!-- List Of Channels -->
      <el-row class="mt-4" :gutter="20">
        <el-col :span="8" v-for="(channel, index) in channels" :key="index">
          <div class="mb-6 border bg-white rounded-lg hover:bg-white hover:shadow-white-lg">
            <!-- Channel Info -->
            <div class="px-5 py-5">
              <div class="flex">
                <div class="flex-1">
                  <img :src="channel.image" class="w-12" />
                </div>
                <div class="flex-none">
                  <!-- Coming Soon -->
                  <template v-if="channel.comingsoon">
                    <div class="py-1 px-2 bg-gray-200 border-t rounded-full text-xs text-gray-500">Coming Soon</div>
                  </template>
                  <template v-else>
                    <el-button type="primary" round size="mini" @click="onAddchannel(channel.type)"> Install</el-button>
                  </template>
                </div>
              </div>
              <!-- Name -->
              <div class="mt-4 font-medium text-gray-700">{{ channel.name }}</div>

              <!-- Description -->
              <div class="my-1 text-xs text-gray-500">{{ channel.description }}</div>
            </div>
          </div>
        </el-col>
      </el-row>

      <!-- Instagram Integrate Popup -->
      <InstagramIntegrationPopup ref="InstagramIntegrationPopup"></InstagramIntegrationPopup>
      <WhatsAppIntegrationPopup ref="WhatsAppIntegrationPopup"></WhatsAppIntegrationPopup>
      <FbMessengerIntegrationPopup ref="FbMessengerIntegrationPopup"></FbMessengerIntegrationPopup>
    </div>
  </div>
</template>

<script>
import InstagramIntegrationPopup from './instagramIntegrationPopup.vue';
import WhatsAppIntegrationPopup from './whatsApp/whatsAppIntegrationPopup.vue';
import FbMessengerIntegrationPopup from './fbMessengerIntegrationPopup.vue';
export default {
  components: {
    InstagramIntegrationPopup,
    WhatsAppIntegrationPopup,
    FbMessengerIntegrationPopup
  },
  data() {
    return {
      channels: [
        {
          name: 'WhatsApp',
          type: 'whatsapp',
          description: 'Integrate Whatsapp and handle customer queries on Whatsapp via Growlytics.',
          image: require('@/assets/images/messaging-channels/whatsapp.png'),
          comingsoon: false
        },
        {
          name: 'Instagram',
          type: 'instagram',
          description: 'Integrate Instagram with Growlytics to enable teams to manage Instagram DM messages.',
          image: require('@/assets/images/messaging-channels/insta.png'),
          comingsoon: false
        },
        {
          name: 'Facebook Messenger',
          type: 'facebook_messenger',
          description: `Integrate Facebook M and handle customer queries on your brand's facebook page.`,
          image: require('@/assets/images/messaging-channels/fb.png'),
          comingsoon: true
        },
        {
          name: 'Email',
          type: 'email',
          description: 'Integrate Email and handle customer queries facebook messages via Growlytics.',
          image: require('@/assets/images/messaging-channels/email.png'),
          comingsoon: true
        }
      ]
    };
  },

  methods: {
    onAddchannel(channelName) {
      if (channelName == 'instagram') {
        this.$refs.InstagramIntegrationPopup.showDialog();
      } else if (channelName == 'whatsapp') {
        this.$refs.WhatsAppIntegrationPopup.showDialog();
      } else if (channelName == 'facebook_messenger') {
        this.$refs.FbMessengerIntegrationPopup.showDialog();
      }
    },

    //#region ---------------------- Start: Manage Instagram Channel ----------------------

    async onAddInstagramChannel() {}

    //#endregion ------------------- End: Manage Instagram Channel ----------------------
  }
};
</script>

<style></style>
